import gsap from 'gsap';
import waveSvg from '../../static/wave-shape-divider.svg';
//import spinnerSvg from '../../static/page-assets/home/LuckyEye.svg';
export class Helpers {
    static isLoading = false;
    static loadingMessage = "";
    static isFinished = false;
    static transitionElement = null;
    static speed = .9;
    static transitionTimelines = [];


    /**
     * 
     * @param {*} waitDuration the duration to wait for once hidden
     * @param {*} speed the speed to hide with
     * @returns 
     */
    static playTransitionAnimation(waitDuration = 0, speed = .9) {
        return new Promise((resolve, reject) => {
            // Create transition element
            const transEl = document.createElement("div");
            transEl.innerHTML = `<div id="sb-transition-wave-top">${waveSvg}</div>
            <div id="sb-transition-wave-bottom">${waveSvg}</div>
            
            <style>
            #sb-pp-overlay {
                z-index: 1000;
            }
            </style>

            `;
            transEl.id = "sb-transition-container";
            document.body.appendChild(transEl);


            const tl = gsap.timeline({ paused: false });
            tl.from("#sb-transition-container", {
                y: "200vh",
                scale: 2,
                duration: speed,
                onComplete: resolve
            });
            tl.to("#sb-transition-container", {
                y: "-200vh",
                scale: 1,
                duration: speed,
                delay: waitDuration

            })

            tl.eventCallback("onComplete", () => { transEl.remove() })
        });
    }


    static clearTransitionTimelines() {
        for (let i = 0; i < this.transitionTimelines.length; i++) {
            const tl = this.transitionTimelines.pop();
            tl.kill();
        }
    }

    static startLoading(message = "") {
        this.loadingMessage = message;

        if (this.transitionElement) {
            this.transitionElement.remove();
        }

        if (this.isLoading) {
            return;
        }

        this.clearTransitionTimelines();

        this.isLoading = true;
        console.log("IM: Started loading...", message);

        return new Promise(function(resolve, reject) {
            // Create transition element
            Helpers.transitionElement = document.createElement("div");
            this.transitionElement.innerHTML = `<div id="sb-transition-wave-top">${waveSvg}</div>
            <div id="sb-transition-wave-bottom">${waveSvg}</div>
            <div id="sb-transition-spinner"><lottie-player src="auge.json"  background="transparent"  style="width: 90px;" speed="1" loop preserveAspectRatio autoplay></lottie-player></div>
            
            <style>
            #sb-pp-overlay {
                z-index: 1000;
            }
            </style>

            `;
            Helpers.transitionElement.id = "sb-transition-container";
            document.body.appendChild(Helpers.transitionElement);

            const tl = gsap.timeline({ paused: false });
            this.transitionTimelines.push(tl);
            tl.from("#sb-transition-container", {
                y: "200vh",
                scale: 2,
                duration: Helpers.speed,
                onComplete: resolve
            });
        }.bind(this));

    }

    static stopLoading(minDuration = 0) {
        if (!this.isLoading) {
            return;
        }

        this.clearTransitionTimelines();

        this.isLoading = false;
        // Remove the transition again

        return new Promise(function(resolve, reject) {
            const tl = gsap.timeline({ paused: false });
            this.transitionTimelines.push(tl);
            tl.to("#sb-transition-container", {
                y: "-200vh",
                scale: 1,
                duration: this.speed,
                delay: minDuration,
                onComplete: function() {
                    if (this.transitionElement) {
                        this.transitionElement.remove();
                    }

                    this.isLoading = false;
                    this.loadingMessage = "";
                    console.log("IM: Finished Loading", this.loadingMessage);
                    resolve();
                }
            });
        }.bind(this));

    }

    /**
     * Default hide tween function
     * @param {*} selector selector of the object
     * @param {*} duration duration of hide transition
     * @returns promise that resolves once hide animation is completed
     */
    static defaultHide(selector, duration = .5) {
        return new Promise((resolve, reject) => {
            gsap.to(selector, { opacity: 0, duration, onComplete: resolve });
        })
    }


    /**
     * Helpers method to get the pages to all files in awards folders
     * @returns all paths to award-logos
     */
    static getAwardPaths() {
        // Get paths to all files in logos folder
        const allAwards = (ctx => {
            let keys = ctx.keys();
            let paths = [];
            for (const key of keys) {
                // Push path and remove whitespaces
                paths.push(("/awards-logos/" + key.substring(2)).replace(" ", ""));
            }
            return paths;
        })(require.context('../../static/awards-logos', true, /\/.*?\.(?:jpg|svg|gif|jpe?g|tiff?|png|webp|bmp)/));
        return allAwards;
    }

    /**
     * Helper method to tet all paths to files in logos folder
     * @returns paths to files in logos folder
     */
    static getLogoPaths() {
        // Get paths to all files in logos folder
        const allLogos = (ctx => {
            let keys = ctx.keys();
            let paths = [];
            for (const key of keys) {
                // Push path and remove whitespaces
                paths.push(("/logos/" + key.substring(2)).replace(" ", ""))
            }
            return paths;
        })(require.context('../../static/logos', true, /\/.*?\.(?:jpg|svg|gif|jpe?g|tiff?|png|webp|bmp)/));
        return allLogos;
    }

    /**
     * Returns default properties for the char entrance animation
     */
    static charEntranceAnimDefaults() {
        return {
            duration: 1,
            opacity: 0,
            rotationX: 90,
            rotationZ: 10,
            y: 10,
            stagger: 0.02
        }
    }

}