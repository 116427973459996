import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { Power4 } from 'gsap';
import { SectionType } from "../enums";
import { Helpers } from '../handlers/helpers';
import { Button } from '../templates/button';
import borderSideSvg from '../../static/page-assets/section-02/Rahmen-Pyramide-side.svg';

import borderTopBotSvg from '../../static/page-assets/section-02/Rahmen-Pyramide-top-bot.svg';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 2.7;

export default {
    // ID of the section
    sectionId: 2,
    // slug of the section (make sure its unique)
    sectionSlug: "s2",
    // Page title of the loaded section
    sectionTitle: "Beratung mit echten Ergebnissen.",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "3_4_digital_pyramide",
    loopVideoID: null,

    loader: '',

    content: /*html*/ `<div id="s2-main-container" class="sb-inner-container sb-above-pp">
    <h2 id="s2-title"><span class="sb-entrance-container">${_lang("S2_Title")}</span></h2>
    <div id="s2-content">
        <p>
            ${_lang("S2_Content_1")}
        </p>
        <p>
            ${_lang("S2_Content_2")}
        </p>        
    </div>
    <a id="s2-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_03.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
</div>
<img id="s2-stoerer" class="sb-section-el s2-section-el" src="/page-assets/section-02/Stoerer.png">
<img id="s2-phyramide" sb-mouse-x="-15" sb-mouse-y="-15" class="s2-section-el sb-mouse-tracking sb-section-el"
    src="/page-assets/section-02/Phyramide.png">
<img id="s2-planet" sb-mouse-x="5" sb-mouse-y="5" class="sb-mouse-tracking sb-section-el s2-section-el"
    src="/page-assets/section-02/Planet.png">
<div id="s2-borders">
    <div id="s2-border-right">${borderSideSvg} </div>
    <div id="s2-border-left">${borderSideSvg} </div>
    <div id="s2-border-top">${borderTopBotSvg} </div>
    <div id="s2-border-bottom">${borderTopBotSvg} </div>
</div>`,

    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {

            const splitText = new SplitText("#s2-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from("#s2-stoerer", { opacity: 0, duration: .6 }, "<");
            tl.from("#s2-content", { opacity: 0 });

            //background timeline
            const bgtl = gsap.timeline({ paused: false });
            // Animate bg elements
            bgtl.add("end-transition", 1.5);
            bgtl.from("#s2-phyramide", { ease: Power4.easeOut, x: '-250%', y: '-40%', filter: "blur(200px)", scale: 1.6, duration: 1.5 }, "end-transition")
            bgtl.from("#s2-borders", { scale: 1.2, opacity: 0, duration: .6 }, "end-transition");

            bgtl.from("#s2-planet", { x: 200, y: 200, opacity: 0, delay: .5, duration: .6, onComplete: resolve }, '<');

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");

            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                bgtl.seek('-=0', true);
                tl.seek('-=0', true);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            bgtl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s2-main-container, .s2-section-el, #s2-borders");
    }
}