import gsap from 'gsap';
import Swiper from 'swiper';
import { SectionType } from '../enums.js';
import { _lang } from '../handlers/langHandler.js';

const defaultColor = "#efeae4" // default slide background color

export default {
    // ID of the section
    sectionId: "methoden",
    // slug of the section (make sure its unique)
    sectionSlug: "methoden",
    // Page title of the loaded section
    sectionTitle: "Methoden",
    // Type of the page
    type: SectionType.CUSTOM,

    content: /*html*/ `<div id="smethoden-container">
<!--    <div id="smethoden-header" class="sb-above-pp">
        <h3 id="smethoden-title">UNSERE METHODEN</h3>
    </div> -->

    <div id="smethoden-slides" class="sb-above-pp">
        <!-- Slider main container -->
        <div class="swiper-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->

                <!--Slide 0-->
                <div class="swiper-slide" sb-active-color="#CD4331">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-start.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-start.png">

                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Digital Business Design Sprints</h2>
                            </div>
                            <div class="sb-slide-content">${_lang("Methods_Digital_Business_Design_Sprints")}
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Slide 1-->
                <div class="swiper-slide" sb-active-color="#6B8682">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-strategy-sprint.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-strategy-sprint.png">

                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Strategy Sprint</h2>
                            </div>
                            <div class="sb-slide-content">
                            ${_lang("Methods_Strategy_Sprint")}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Slide 2-->
                <div class="swiper-slide" sb-active-color="#8EB686">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-creative-exploration.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-creative-exploration.png">
                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Creative Exploration</h2>
                            </div>
                            <div class="sb-slide-content">
                                ${_lang("Methods_Creative_Exploration")}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Slide 3-->
                <div class="swiper-slide" sb-active-color="#DFC3D8">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-design-quest.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-design-quest.png">
                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Design Quest</h2>
                            </div>
                            <div class="sb-slide-content">
                                ${_lang("Methods_Design_Quest")}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Slide 4-->
                <div class="swiper-slide" sb-active-color="#D9C583">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-reality-check.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-reality-check.png">
                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Reality Check</h2>
                            </div>
                            <div class="sb-slide-content">
                                ${_lang("Methods_Reality_Check")}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Slide 5-->
                <div class="swiper-slide" sb-active-color="#30A37C">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-development-sprint.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-development-sprint.png">
                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">Development Sprint</h2>
                            </div>
                            <div class="sb-slide-content">
                                ${_lang("Methods_Development_Sprint")}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Slide 6-->
                <div class="swiper-slide" sb-active-color="#ffA37C">
                    <div class="sb-slide-inner-container">
                        <div class="sb-slide-col">
                            <img src="/page-assets/methoden/methoden-banner-start.png">
                        </div>
                        <div class="sb-slide-col sb-slide-scrollable">
                        <img class="sb-in-content-poster" src="/page-assets/methoden/methoden-banner-start.png">
                            <div class="sb-slide-header">
                                <!--<svg height="2" width="100%">
                                    <line x1="0" y1="0" x2="100%" y2="0" style=";stroke-width:2" />
                                </svg>-->
                                <h2 class="sb-slide-title">${_lang("Methods_Coaching_Training_Advice_HL")}</h2>
                            </div>
                            <div class="sb-slide-content">
                                ${_lang("Methods_Coaching_Training_Advice")}    
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="swiper-button-next swiper-button-white"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 450" style="width: 100%; height: 100%; " preserveAspectRatio="xMidYMid meet"><defs><mask id="arrow_mask"><g style="display: block;" transform="matrix(1,0,0,1,101,0)" opacity="1"><rect width="450" height="450" fill="#ffffff"></rect></g></mask></defs><g><g style="display: block;" transform="matrix(1,0,0,1,325,225)" opacity="1"><g opacity="1" ><path fill="rgb(0,0,0)" fill-opacity="1" d=" M0,-209.5 C115.623046875,-209.5 209.5,-115.623046875 209.5,0 C209.5,115.623046875 115.623046875,209.5 0,209.5 C-115.623046875,209.5 -209.5,115.623046875 -209.5,0 C-209.5,-115.623046875 -115.623046875,-209.5 0,-209.5z"></path></g></g><g style="display: block;stroke: white !important;fill: white !important;" mask="url(#arrow_mask)"><g transform="matrix(1,0,0,1,325,225)" opacity="1"><g opacity="1" ><path fill-opacity="1" d=" M35.5,-62.25 C35.5,-62.25 69.5,-5.5 69.5,-5.5 C69.5,-5.5 -115.75,-15 -115.75,-15 C-115.75,-15 -115.25,16 -115.25,16 C-115.25,16 68,5.5 68,5.5 C68,5.5 35.75,62.25 35.75,62.25 C35.75,62.25 116.75,0 116.75,0 C116.75,0 35.5,-62.25 35.5,-62.25z" fill="white"></path></g></g></g></g></svg></div>
            <div class="swiper-button-prev swiper-button-white"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 450" style="width: 100%; height: 100%; " preserveAspectRatio="xMidYMid meet"><defs><mask id="arrow_mask"><g style="display: block;" transform="matrix(1,0,0,1,101,0)" opacity="1"><rect width="450" height="450" fill="#ffffff"></rect></g></mask></defs><g><g style="display: block;" transform="matrix(1,0,0,1,325,225)" opacity="1"><g opacity="1" ><path fill="rgb(0,0,0)" fill-opacity="1" d=" M0,-209.5 C115.623046875,-209.5 209.5,-115.623046875 209.5,0 C209.5,115.623046875 115.623046875,209.5 0,209.5 C-115.623046875,209.5 -209.5,115.623046875 -209.5,0 C-209.5,-115.623046875 -115.623046875,-209.5 0,-209.5z"></path></g></g><g style="display: block;stroke: white !important;fill: white !important;" mask="url(#arrow_mask)"><g transform="matrix(1,0,0,1,325,225)" opacity="1"><g opacity="1" ><path fill-opacity="1" d=" M35.5,-62.25 C35.5,-62.25 69.5,-5.5 69.5,-5.5 C69.5,-5.5 -115.75,-15 -115.75,-15 C-115.75,-15 -115.25,16 -115.25,16 C-115.25,16 68,5.5 68,5.5 C68,5.5 35.75,62.25 35.75,62.25 C35.75,62.25 116.75,0 116.75,0 C116.75,0 35.5,-62.25 35.5,-62.25z" fill="white"></path></g></g></g></g></svg></div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</div>
`,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {

            const tl = gsap.timeline({ paused: false });
            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)

        })
    },

    hide: () => {
        return new Promise((resolve, reject) => { resolve() });
    },

    dispose: function() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        }
    },

    onLoad: function() {
        this.swiper = new Swiper('#smethoden-slides .swiper-container', {
            direction: 'horizontal',
            pagination: {
                clickable: true,
                el: "#smethoden-slides .swiper-pagination",
                type: "bullets"
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            speed: 1500,
            loop: false,
            width: window.innerWidth,
            slidesPerView: 1,
            slidesPerGroup: 1,
            autoplay: false,
        });

        const swiper = this.swiper;

        const container = document.querySelector("#smethoden-container");

        updateSlideColor();
        swiper.on("slideChange", function() {
            updateSlideColor();

            for (const slide of swiper.slides) {
                const cols = slide.querySelectorAll(".sb-slide-col");
                for (const col of cols) {
                    col.scrollTop = 0;
                }
            }
        });
        /**
         * Updates the color of the background with the active slide's color
         */
        function updateSlideColor() {
            // get the active color
            const activeIndex = swiper.activeIndex;
            const activeSlide = swiper.slides[activeIndex];
            const activeColor = activeSlide.getAttribute("sb-active-color") || defaultColor;
            // update the background
            container.style.background = activeColor;
        }

        const slides = swiper.slides;

        // for the slides content add scrollable class as long as they are scrollable
        for (const slide of slides) {
            const contentContainer = (window.innerHeight > 767) ? slide.querySelector(".sb-slide-content") : slide.querySelector(".sb-slide-scrollable");
            contentContainer.addEventListener("scroll", onScroll);
            onScroll();

            function onScroll() {
                const offset = 15;
                const totalHeight = contentContainer.scrollTopMax ? contentContainer.scrollTopMax : contentContainer.scrollHeight - contentContainer.getBoundingClientRect().height;
                const compareHeight = Math.max(totalHeight - offset, 0);

                // Check if we can scroll further
                if (contentContainer.scrollTop > compareHeight || (totalHeight <= 1 && window.innerWidth > 767)) {
                    contentContainer.classList.remove("sb-scrollable");
                } else {
                    contentContainer.classList.add("sb-scrollable");
                }
            }
        }
    }
}