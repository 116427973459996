import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { SectionType } from "../enums";
import { Helpers } from '../handlers/helpers';
import { _lang } from '../handlers/langHandler';
import { Button } from '../templates/button';
const sectionStartDelay = .7;

export default {
    // ID of the section
    sectionId: 1,
    // slug of the section (make sure its unique)
    sectionSlug: "s1",
    // Page title of the loaded section
    sectionTitle: "Digital Business Design",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "2_3_home_digital",
    loopVideoID: null,

    loader: '',

    content: /*html*/ `
    <div id="s1-main-container" class="sb-inner-container sb-above-pp">
        <h2 id="s1-title"><span class="sb-entrance-container">Digital<br>Business Design</span></h2>
        <div id="s1-content">
        <p>${_lang("S1_Content_1")}</p>
        <p>${_lang("S1_Content_2")}</p>
        <p>${_lang("S1_Content_3")}</p>
        </div>
        <a id="s1-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_01.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
        <br>
        <!--<a id="s1-moarnext" sb-override-to-id="4" sb-override-vid-id="dbd_phoenix" class="sb-nav-linked">About</a>-->
    </div>
    `,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {

            const splitText = new SplitText("#s1-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;


            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<");
            tl.from("#s1-content", { opacity: 0, duration: .6 });
            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 });


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s1-main-container");
    }
}