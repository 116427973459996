export class Button {
    constructor(text) {
        this.text = text;

        this.content = `
        <svg id="a9ffe189-9ff4-419e-b12f-460f781a4183" class="sb-im-button" data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 456 457">
    <defs>
        <filter id="bcb10313-38f8-4d01-b651-ccb968e0fbd6" x="-5.1%" y="-11.9%" width="110.2%" height="132%" name="filter-3">
            <feOffset result="shadowOffsetOuter1" dy="4" in="SourceAlpha"></feOffset>
            <feGaussianBlur result="shadowBlurOuter1" stdDeviation="4.5" in="shadowOffsetOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.37 0" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <mask id="af2a3d57-955f-4513-87c8-ff96804077b5" x="0" y="0" width="456" height="457" maskUnits="userSpaceOnUse">
            <g transform="translate(-108.5 175.5)">
                <g id="e947bacb-6808-4e17-8d10-a9d6683aab29" data-name="mask-2">
                    <path id="f9e8905f-d14a-472b-a813-a9eb7824bddb" data-name="path-1" d="M459.61,18.41V5H215.39V18.41C196.07,18.41,185,34.12,185,53.5s11.07,35.09,30.39,35.09V102H459.61V88.59C478.94,88.59,490,72.88,490,53.5S478.94,18.41,459.61,18.41Z" style="fill:#fff;fill-rule:evenodd"></path>
                </g>
            </g>
        </mask>
    </defs>
    <g id="e354885e-c4fa-42a4-9327-384e4b008981" class="sb-im-button-inner" data-name="1080x1920">
        <g id="f023e13b-f753-4cf3-ab59-1743f8498c13" data-name="1">
            <g id="bdf4d644-1975-410d-a8c0-68a59ca4dc8c" data-name="Group-11-Copy">
                <g id="b9b8c534-6eb7-4e1e-aae4-526d00504764" data-name="Group-19">
                    <g id="ef41e09a-83b4-4a59-8768-c33e116e2aeb" data-name="Mask">
                        <g style="filter:url(#bcb10313-38f8-4d01-b651-ccb968e0fbd6)">
                            <path id="bbe3d69c-8a6c-493b-bd6d-a038316af022" data-name="path-1" d="M459.61,18.41V5H215.39V18.41C196.07,18.41,185,34.12,185,53.5s11.07,35.09,30.39,35.09V102H459.61V88.59C478.94,88.59,490,72.88,490,53.5S478.94,18.41,459.61,18.41Z" transform="translate(-108.5 175.5)" style="fill-rule:evenodd"></path>
                        </g>
                        <path id="a57c7e81-898a-40fc-ae38-79ce4d1e2343" data-name="path-1" d="M459.61,18.41V5H215.39V18.41C196.07,18.41,185,34.12,185,53.5s11.07,35.09,30.39,35.09V102H459.61V88.59C478.94,88.59,490,72.88,490,53.5S478.94,18.41,459.61,18.41Z" transform="translate(-108.5 175.5)" style="fill:#e0442e;fill-rule:evenodd"></path>
                    </g>
                    <g style="mask:url(#af2a3d57-955f-4513-87c8-ff96804077b5)">
                        <g id="b30d0746-153b-4e24-9c96-7184362fedba" class="sb-rotate" data-name="Group-43">
                            <polygon id="b37537a2-fca3-4f6a-9e61-6113da944482" data-name="Fill-1" points="203.5 1.5 229 1.5 254.5 1.5 229 229.5 203.5 1.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="eec5bc46-7b3d-40e3-b5ce-0b2b08825caf" data-name="Stroke-2" points="203.5 1.5 229 1.5 254.5 1.5 229 229.5 203.5 1.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="e80862ea-0e12-4277-9c4b-7ec0160f8309" data-name="Fill-4" points="254.5 455.5 229 455.5 203.5 455.5 229 227.5 254.5 455.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="a2b87be6-5516-4f43-8098-eb6586284b9b" data-name="Stroke-5" points="254.5 455.5 229 455.5 203.5 455.5 229 227.5 254.5 455.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="faca13dc-f6b8-4b72-824e-fbbdbb2338a0" data-name="Fill-7" points="454.5 203.5 454.5 229 454.5 254.5 226.5 229 454.5 203.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="a61c9793-3a1b-4a38-befb-6d6d501acff7" data-name="Stroke-8" points="454.5 203.5 454.5 229 454.5 254.5 226.5 229 454.5 203.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="bc6fe231-10f3-4dbb-bac5-c3775fa52657" data-name="Fill-10" points="1.5 254.5 1.5 229 1.5 203.5 229.5 229 1.5 254.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="b52003c8-4d0c-49b1-a15e-8b5380cc41a1" data-name="Stroke-11" points="1.5 254.5 1.5 229 1.5 203.5 229.5 229 1.5 254.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="a0566e6f-7831-4ae4-b35b-63a6a87da9ce" data-name="Fill-13" points="382.25 60.5 389.37 67.62 396.5 74.75 227.5 229.5 382.25 60.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="b1138965-dbd6-4dcf-9d2f-030231799ffa" data-name="Stroke-14" points="382.25 60.5 389.37 67.62 396.5 74.75 227.5 229.5 382.25 60.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="f35d56eb-8c1c-4c87-bf01-18cefedb7a17" data-name="Fill-15" points="73.75 396.5 66.63 389.38 59.5 382.25 228.5 227.5 73.75 396.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="f18d30c9-dd94-445d-a64c-235c8b97d3bf" data-name="Stroke-16" points="73.75 396.5 66.63 389.38 59.5 382.25 228.5 227.5 73.75 396.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="bb32e5c2-b78a-4b68-a973-4bebed525932" data-name="Fill-17" points="396.5 382.25 389.38 389.37 382.25 396.5 227.5 227.5 396.5 382.25" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="b32835ee-ea6a-4cf9-828e-f5ff5aa4a10c" data-name="Stroke-18" points="396.5 382.25 389.38 389.37 382.25 396.5 227.5 227.5 396.5 382.25" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="bc13d001-0f4a-4c6a-bd4e-9f117777aaef" data-name="Fill-19" points="59.5 74.75 66.62 67.63 73.75 60.5 228.5 229.5 59.5 74.75" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="fb573c9c-0e52-46b1-99ee-404306b91f08" data-name="Stroke-20" points="59.5 74.75 66.62 67.63 73.75 60.5 228.5 229.5 59.5 74.75" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="a6eb6cf6-2e85-4981-81a9-c346787833c3" data-name="Fill-21" points="324.5 434.78 315.2 438.64 305.91 442.5 227.5 227.5 324.5 434.78" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="bef0e0b5-b0aa-487a-a1b3-4285c8e2f4f8" data-name="Stroke-22" points="324.5 434.78 315.2 438.64 305.91 442.5 227.5 227.5 324.5 434.78" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="b916f43d-a3ba-4d43-932c-83b4fa97d976" data-name="Fill-24" points="131.5 22.22 140.8 18.36 150.09 14.5 228.5 229.5 131.5 22.22" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="a7fd440a-2499-43db-b549-35625125715a" data-name="Stroke-25" points="131.5 22.22 140.8 18.36 150.09 14.5 228.5 229.5 131.5 22.22" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="ac3d0e24-9019-44f5-bb42-73918538de0e" data-name="Fill-26" points="131.5 434.78 140.8 438.64 150.09 442.5 228.5 227.5 131.5 434.78" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="ba1f89c6-7b22-4c4a-8899-a42201b641f8" data-name="Stroke-27" points="131.5 434.78 140.8 438.64 150.09 442.5 228.5 227.5 131.5 434.78" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="a1ca825e-37e9-4723-ae39-df3fb5acf157" data-name="Fill-29" points="324.5 22.22 315.2 18.36 305.91 14.5 227.5 229.5 324.5 22.22" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="b6c75afa-08f1-4cb8-844a-a7ab6583e11f" data-name="Stroke-30" points="324.5 22.22 315.2 18.36 305.91 14.5 227.5 229.5 324.5 22.22" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="ff0fe076-ee18-4352-b029-123794b58220" data-name="Fill-31" points="382.25 60.5 389.37 67.62 396.5 74.75 227.5 229.5 382.25 60.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="e240e8bc-4feb-4887-86d0-c68b08621b76" data-name="Stroke-32" points="382.25 60.5 389.37 67.62 396.5 74.75 227.5 229.5 382.25 60.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="ae992e2a-5f57-4490-ad27-e5c0d4047e57" data-name="Fill-33" points="73.75 396.5 66.63 389.38 59.5 382.25 228.5 227.5 73.75 396.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="fdb0dce6-6b9d-41d2-84aa-392fcf2cf8d0" data-name="Stroke-34" points="73.75 396.5 66.63 389.38 59.5 382.25 228.5 227.5 73.75 396.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="b44729ba-7feb-4bfc-a8f0-fbf2b09cfc51" data-name="Fill-35" points="433.59 130.5 437.54 139.73 441.5 148.96 227.5 229.5 433.59 130.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="a90008c4-576f-4795-aec8-7016bab71c6e" data-name="Stroke-36" points="433.59 130.5 437.54 139.73 441.5 148.96 227.5 229.5 433.59 130.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="ef7de287-e45d-462b-8db3-9226cb35945a" data-name="Fill-37" points="23.41 326.5 19.46 317.27 15.5 308.04 229.5 227.5 23.41 326.5" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="ab92068f-4129-4221-9174-21866a16b7f5" data-name="Stroke-38" points="23.41 326.5 19.46 317.27 15.5 308.04 229.5 227.5 23.41 326.5" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="f861bbcc-7af6-4044-ab87-daa9864e9351" data-name="Fill-39" points="439.5 311.16 435.4 320.33 431.31 329.5 226.5 227.5 439.5 311.16" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="f4b50446-1117-4823-be8d-5aca9c11e896" data-name="Stroke-40" points="439.5 311.16 435.4 320.33 431.31 329.5 226.5 227.5 439.5 311.16" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                            <polygon id="e833493b-7867-49d2-88c8-d5a43b32413a" data-name="Fill-41" points="16.5 145.84 20.59 136.67 24.69 127.5 229.5 229.5 16.5 145.84" style="fill:#f18bbb;fill-rule:evenodd"></polygon>
                            <polygon id="f39db472-788b-44a5-a10c-3cc2388620c4" data-name="Stroke-42" points="16.5 145.84 20.59 136.67 24.69 127.5 229.5 229.5 16.5 145.84" style="fill:none;stroke:#ec8ab6;stroke-width:3px"></polygon>
                        </g>
                    </g>
                </g>
                <path id="bf4bfa18-a547-4212-b0ee-5fa856185522" data-name="outline" d="M458.36,6.25V19.68c9.51-.34,17,3.2,22.19,9.17,5.36,6.19,8.2,14.95,8.2,24.65s-2.84,18.46-8.2,24.65c-5.16,6-12.68,9.51-22.19,9.17h0v13.43H216.64V87.32c-9.51.34-17-3.2-22.19-9.17-5.36-6.19-8.2-15-8.2-24.65s2.84-18.46,8.2-24.65c5.16-6,12.68-9.51,22.19-9.17h0V6.25Z" transform="translate(-108.5 175.5)" style="fill:none;stroke:#412d71;stroke-width:2.5px"></path>
                <path id="e9ff5a56-6094-4641-80ad-2cdda2baa1f7" data-name="inner" d="M448.92,25.43V17H225.08v8.43a28.07,28.07,0,1,0,0,56.14V90H448.92V81.57a28.07,28.07,0,1,0,0-56.14" transform="translate(-108.5 175.5)" style="fill:#412d71;stroke:#412d71;fill-rule:evenodd"></path>
                <text transform="translate(130.97 236.33)" style="font-size:24px; fill:#fff;font-family:bw, Arial;text-anchor: middle;">
                    <tspan x="98.21" y="0" style="letter-spacing:0.1em">${this.text}</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
        
        
        `
    }
}