// load blotter lib and material

import gsap from 'gsap';
import 'particles.js';


import { SectionType } from "../enums";
import { setPage, state } from '../script';
import eye from '../../static/page-assets/home/LuckyEye.svg';
import diamond from '../../static/page-assets/home/diamond.svg';
import incredibleLogo from '../templates/incredibleLogo';
import movingClouds from '../templates/movingClouds';

const autoTransitionDelay = 2000;
const sectionStartDelay = 0;

export default {
    // ID of the section
    sectionId: "home",
    // slug of the section (make sure its unique)
    sectionSlug: "home",
    // Page title of the loaded section
    sectionTitle: "Home",
    // Type of the page
    type: SectionType.CUSTOM,


    content: /*html*/ `
    ${incredibleLogo.template}
    ${movingClouds.template}
    <div id="shome-stars"></div>
    <div id="shome-eye" style="text-align:center;"><lottie-player src="auge.json"  background="transparent"  style="width: 90px;" speed="1" loop preserveAspectRatio autoplay></lottie-player></div>
    <div id="shome-bluerect"><img src="/page-assets/home/diamond.png"></div>
    `,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            const container = document.querySelector("#sb-content-container[page-id='home']");
            container.classList.add("sb-loaded");
            resolve();
        });

    },
    onLoad() {

        movingClouds.init();

        // const eyeEl = document.querySelector("#shome-eye");
        // eyeEl.style.cursor = "pointer";
        // const elClick = this.startPageTransition.bind(this);
        // eyeEl.addEventListener("click", elClick);

        if (state.isLoaded) {
            setTimeout(() => {
                this.startPageTransition();
            }, autoTransitionDelay);
        }

        //particlesJS("shome-stars", particleConfig);


        incredibleLogo.init();

    },

    dispose: () => {
        incredibleLogo.dispose();
        movingClouds.dispose();
    },

    startPageTransition() {
        const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
        /*tl.from("#shome-eye #eye", { transform: "rotateX(180deg)", duration: .6 });*/
        tl.to("#shome-eye", { bottom: "auto", ease: "easeIn", duration: .9 })
        tl.to("#scomponent-im-text", { y: -100, opacity: 0, duration: .6 }, "<");
        tl.to("#shome-bluerect", { scale: 1, rotation: 90, duration: 1 })
        tl.to("body", {
            background: "#43B3E7",
            duration: .3,
            onComplete: () => {
                setPage(0);
            }
        });
    }
}

const particleConfig = {
    "particles": {
        "number": {
            "value": 60,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            "value": "#ffffff"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "value": 1,
            "random": true,
            "anim": {
                "enable": true,
                "speed": 1,
                "opacity_min": 0,
                "sync": false
            }
        },
        "size": {
            "value": 2,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 4,
                "size_min": 0.3,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
        },
        "move": {
            "enable": true,
            "speed": 0.1,
            "direction": "none",
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 600
            }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            },
            "onclick": {
                "enable": false,
                "mode": "repulse"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 100,
                "size": 0,
                "duration": 2,
                "opacity": 0.6,
                "speed": 3
            },
            "repulse": {
                "distance": 400,
                "duration": 0.4
            },
            "push": {
                "particles_nb": 4
            },
            "remove": {
                "particles_nb": 2
            }
        }
    },
    "retina_detect": true
};