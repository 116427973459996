// load styles
import './styles/style.scss'

// Load all section  in the directory styles/sections/* automatically
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./styles/sections/', true, /\.scss$/));

//import * as PIXI from 'pixi.js';
//import { Texture, BaseTexture } from 'pixi.js';
//import { settings } from '@pixi/settings';
//import { getResolutionOfUrl } from '@pixi/utils';
import gsap from 'gsap';
import { SplitText } from "gsap/SplitText";
import * as LottiePlayer from "@lottiefiles/lottie-player";

import { MouseHandler } from './handlers/mouseHandler.js';
import { NavHandler } from './handlers/navHandler.js';
import { Helpers } from './handlers/helpers';

import { DisplayMode, Languages, SectionType } from "./enums";
import { PostProcessing } from './handlers/postProcessing';
import { HistoryHandler } from './handlers/historyHandler';
import { LangHandler } from './handlers/langHandler.js';
import { AudioHandler } from './handlers/audioHandler';
import videoMapping from './videoMapping';

// Load all section styles automatically and push them into a map from id to section object
const context = require.context('./sections/', true, /^\.\/(?!index\.js).*\.js$/);
export const sectionMap = {};
export const slugIdMap = {};

context.keys().forEach((module) => {
    const section = (context(module).default);
    try {
        sectionMap[section.sectionId] = section;
        slugIdMap[section.sectionSlug] = section.sectionId;
    } catch (e) {}
});

// Initialize the history and lang handlers
const historyHandler = new HistoryHandler();
const langHandler = new LangHandler(); // initialize handlers
const audioHandler = new AudioHandler();

export const state = {
    debug: false,

    isLoaded: false,

    isTransitioningPages: false,

    enablePagination: false, // wether to enable the debug pagination

    transitionDuration: (window.innerWidth < 1024) ? 1.3 : .6, // transition animation duration

    contentContainer: null, // stores the content container dom element
    appInnerContainer: null, // stores the app inner container for the background video playback

    landingPageId: "home", // id of the section that is the landing page

    enableFilmGrain: false, // whether to enable the film grain

    currentSection: null, // reference to the current section
    prevSection: null, // reference to the previously loaded section

    progressBarEstimateDuration: 10,
    progressBarSpeedupDuration: 0.1,

    progressBarEstimateDurationDesktop: 0.2,
    progressBarSpeedupDurationDesktop: 0.03,

    // sizing ..
    scale: 1,
    aspect: null,
    displayMode: '',

    activeVidZindexStack: -1,

    //navigation
    navContainer: null,
    navContent: null,
    navPagination: null,
    isMenuOpen: false,
}

// Call the initialization
init();

/**
 * Initialization, main entry point of everything
 */
function init() {
    gsap.registerPlugin(SplitText);

    // Find the content container
    state.contentContainer = document.querySelector("#sb-content-container");
    state.appInnerContainer = document.getElementById("appInner");

    // Increment the iteration attribute, used for tracking if the current page is not changing in the meantime
    state.contentContainer.setAttribute("sb-i", 0);

    // Initialise the mouse tracking
    MouseHandler.setupTracking();
    // Link the navigation
    NavHandler.linkNav();

    // Enable the film grain if necessary
    if (state.enableFilmGrain) {
        PostProcessing.init();
    }

    state.scale = Math.max(window.innerWidth / state.vidW, window.innerHeight / state.vidH)
        // Calculate sizes and link resize handlers
    console.log("IM: init resizeHandler");
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    // Arrow navigation for debugging
    if (state.debug) {
        initArrowNav()
    }

    // generate the video textures
    historyHandler.init();
    audioHandler.init();


}

/**
 * Helper function to generate all video textures for the sections
 */
export function generateVideoTextures() {
    return new Promise(async(resolve, reject) => {
        console.log("IM: generating video's");
        const PROGRESS_BAR_CONTAINER = document.querySelector("#sb-progress-container");
        const NUMBER_OF_VIDEOS = Object.keys(videoMapping).length;
        const PERC_PER_TICK = 100 / NUMBER_OF_VIDEOS;

        let currentTargetPerc = 0;

        // Create a video element for all videos in the mapping
        for (const VID_KEY in videoMapping) {
            currentTargetPerc = currentTargetPerc + PERC_PER_TICK;
            progressToTarget(currentTargetPerc, false);

            const VID_DATA = videoMapping[VID_KEY][state.displayMode];
            const VID_EL = await createVideo(VID_DATA.url);
            VID_EL.muted = true;
            VID_EL.currentTime = 0.0;
            VID_EL.pause();
            VID_EL.id = "sb-vid-" + VID_KEY;

            state.appInnerContainer.appendChild(VID_EL);

            await progressToTarget(currentTargetPerc, true);
        }
        console.log("IM: Video's generated");
        PROGRESS_BAR_CONTAINER.remove();
        state.isLoaded = true;
        resolve();
    })
}

export function reloadVideo(video) {

}

var progressGsapRef = null;
var progressBarEl = document.querySelector("#sb-progress-container .sb-progress-bar .sb-progress-bar-inner");
/**
 * Simple helper function that progresses the bar
 * @param {*} targetPerc the new target percention to move to
 * @param {*} speedup wether to do it with speedup rate
 * @returns resolves once finished 
 */
function progressToTarget(targetPerc, speedup) {
    return new Promise((resolve, reject) => {
        if (progressGsapRef) {
            progressGsapRef.kill();
        }
        let duration;

        if (state.displayMode == DisplayMode.DESKTOP) {
            duration = speedup ? state.progressBarSpeedupDurationDesktop : state.progressBarEstimateDurationDesktop;
        } else {
            duration = speedup ? state.progressBarSpeedupDuration : state.progressBarEstimateDuration;
        }


        progressGsapRef = gsap.to(progressBarEl, { width: targetPerc + "%", duration, onComplete: resolve });
    });

}

/**
 * Created and preloads a video from url
 * @param {*} url the url to the video
 * @returns the loaded video element
 */
function createVideo(url) {
    return new Promise((resolve, reject) => {
        const VIDEO_EL = document.createElement("video");
        VIDEO_EL.classList.add("sb-video-el");
        VIDEO_EL.setAttribute("muted", true);
        VIDEO_EL.muted = true;
        VIDEO_EL.autoplay = true;
        VIDEO_EL.setAttribute("webkit-playsinline", true);
        VIDEO_EL.preload = "auto";
        VIDEO_EL.style.display = "none";
        VIDEO_EL.setAttribute("playsinline", true);
        VIDEO_EL.playsInline = true;

        // var req = new XMLHttpRequest();
        // req.open('GET', url, true);
        // req.responseType = 'blob';

        VIDEO_EL.src = url;
        VIDEO_EL.addEventListener("canplaythrough", function() {
            resolve(VIDEO_EL);
        }, {once: true})
        VIDEO_EL.load();
        

        // req.onload = function(err) {
        //     // Onload is triggered even on 404
        //     // so we need to check the status code
        //     if (this.status === 206 || this.status === 200) {
        //         // Video is now downloaded
        //         // and we can set it as source on the video element
        //         if ('srcObject' in VIDEO_EL) {
        //             try {
        //                 VIDEO_EL.srcObject = this.response;
        //             } catch (err) {
        //                 if (err.name != "TypeError") {
        //                     alert(err);
        //                     throw err;
        //                 }
        //                 // Even if they do, they may only support MediaStream
        //                 VIDEO_EL.src = URL.createObjectURL(this.response);
        //             }
        //         } else {
        //             VIDEO_EL.src = URL.createObjectURL(this.response);
        //         }

        //         VIDEO_EL.muted = true;

        //         VIDEO_EL.pause();
        //         VIDEO_EL.currentTime = 0;


        //         resolve(VIDEO_EL);
        //     } else {
        //         reject(err);
        //     }
        // }
        // req.onerror = function(err) {
        //     // Error
        //     reject(err);
        //     console.error("IM-createVideo:", err);
        // }
        // req.send();
    });

}




function switchToVideo(videoID, settings, callback) {
    const vidDefaults = {
        fastSwitch: false,
        loop: false
    }

    const VID_SETTINGS = { vidDefaults, ...settings };
    return new Promise(async(resolve, reject) => {
        try {
            const VIDEO_EL = document.querySelector(`#sb-vid-${videoID}.sb-video-el`);
            const PREVIOUS_VID_EL = document.querySelector(`.sb-video-el.sb-active`);

            if (VIDEO_EL === PREVIOUS_VID_EL) {
                resolve();
                return;
            }

            state.activeVidZindexStack++;

            if (VIDEO_EL) {
                VIDEO_EL.style.background = "black";
                // Start new video
                VIDEO_EL.pause();
                VIDEO_EL.currentTime = VID_SETTINGS.fastSwitch ? Number(VIDEO_EL.duration - 0.005) : 0;
                VIDEO_EL.loop = VID_SETTINGS.loop;

                VIDEO_EL.addEventListener("ended", callback, { once: true })
                console.log("Starting play");
                await VIDEO_EL.play();
                console.log("play finished");

                VIDEO_EL.style.display = "";
                VIDEO_EL.style.zIndex = state.activeVidZindexStack;
                VIDEO_EL.classList.add("sb-active");
            } else {
                state.activeVidZindexStack--;
                resolve();
                return;
            }

            state.activeVidZindexStack--;


            // Reset old video
            if (PREVIOUS_VID_EL) {
                PREVIOUS_VID_EL.classList.remove("sb-active");
                PREVIOUS_VID_EL.pause();
                PREVIOUS_VID_EL.style.zIndex = null;

                // Once we've removed a video again from the stack we decrease the zindex of the active video
                if (VIDEO_EL) {
                    VIDEO_EL.style.zIndex = state.activeVidZindexStack;
                }

                setTimeout(function() {
                    if (!PREVIOUS_VID_EL.classList.contains("sb-active")) {
                        PREVIOUS_VID_EL.currentTime = 0;
                        PREVIOUS_VID_EL.style.display = "none";
                    }
                }, 300);

            }

            resolve();
        } catch (e) {
            alert(e);
        }
    })
}

/**
 * Update the "page" html content 
 * @param {*} id id of the page
 * @param {*} fastSwitch whether to switch fast
 * @param {*} pushHistory whether to push the page switch into the history stack
 */
export async function setPage(id, fastSwitch = false, pushHistory = true, overrideVidId = false) {
    console.log("IM: Setting Page...", id);

    const section = sectionMap[id]; // get the section object 
    // If it is the same section return
    if (state.currentSection) {
        if (state.currentSection.sectionId == id) {
            return;
        }
    }

    if (!section) {
        return;
    }

    if (state.isTransitioningPages) {
        return;
    }

    state.isTransitioningPages = true;
    console.log("IM: Started transitioning pages");

    // If we are fast switching play the transition to mask the loading etc
    if (fastSwitch) {
        await Helpers.startLoading();
    }

    // Set the previouw page to the current page
    state.prevSection = state.currentSection;

    // Update the current page with the new page
    state.currentSection = section;

    // Set default renderer background colour to freeze frame colour
    //TODO: Set background color correctly.
    //state.app.renderer.backgroundColor = "0x43B3E7"

    // If the section is a slide try to switch the background video as welol
    if (section.type == SectionType.SLIDE) {
        await switchToVideo(overrideVidId || section.introVideoID, { fastSwitch }, () => {
            // Check if we should loop
            if (section.loopVideoID) {
                switchToVideo(section.loopVideoID, { loop: true });
            }
        });
    } else {
        // clear video
        await switchToVideo(null);
    }

    // If there exists a previous section and the current one is a slide
    // call the hide on the previous section first
    if (state.prevSection && section.type == SectionType.SLIDE) {
        // Try to hide the previous section
        try {
            await state.prevSection.hide();
        } catch (err) {}
    }

    try { state.prevSection.dispose() } catch (e) { console.warn(e) };


    // Update the bullet in the pagination
    NavHandler.focusBullet(id);

    // Update the current page attribute for style selectors
    state.contentContainer.setAttribute("page-id", id);
    state.contentContainer.setAttribute("type", section.type);

    // Increment the iteration attribute, used for tracking if the current page is not changing in the meantime
    const iteration = Number(state.contentContainer.getAttribute("sb-i")) + 1;
    state.contentContainer.setAttribute("sb-i", iteration);

    // Load the correct html content of the section
    state.contentContainer.innerHTML = section.content;

    // reset the is shown attribute to  false
    state.contentContainer.setAttribute("show-ended", false)

    // connect the handler
    reConnectPageHandlers(id);

    // Push the new page state to the history
    if (pushHistory) { historyHandler.pushNewPage(id) };

    // if the current page has an on load function call, it
    if (typeof section.onLoad == 'function') {
        try {
            section.onLoad();
        } catch (e) { console.warn("IM: SetPage OnLoad Error:", e) }
    }

    // if the page has a show function call it
    if (typeof section.show == 'function') {
        try {
            (section.show(fastSwitch)).then(() => {
                // if we are still on the same page
                if (state.contentContainer.getAttribute("page-id") == id && iteration == state.contentContainer.getAttribute("sb-i")) {
                    state.contentContainer.setAttribute("show-ended", true);
                }
            });
        } catch (e) { console.warn("IM: SetPage On page Show Error:", e) }
    }
    state.contentContainer.style.visibility = "visible";

    audioHandler.linkClickSounds();

    Helpers.stopLoading(state.transitionDuration);


    state.isTransitioningPages = false;
    console.log("IM: Stopped transitioning pages");
}


/**
 * Calculate the display modes and resizing
 */
function resizeHandler() {
    // calculate aspect ratio
    state.aspect = Math.max(window.innerWidth / 720, window.innerHeight / 1280);

    /* mobile/desktop asset switching */
    if (state.aspect > 1.1) {
        console.log("IM: show desktop vids"); // debug
        state.displayMode = DisplayMode.DESKTOP;
        document.documentElement.classList.remove("mobile-view");
        document.documentElement.classList.add("desktop-view");
        state.vidW = 1920;
        state.vidH = 1080;
    } else {
        console.log("IM: show mobile vids"); // debug
        state.displayMode = DisplayMode.MOBILE;
        document.documentElement.classList.remove("desktop-view");
        document.documentElement.classList.add("mobile-view");
        state.vidW = 720;
        state.vidH = 1280;
    }

    /* calculate fullscreen scale factor */
    state.scale = Math.max(window.innerWidth / state.vidW, window.innerHeight / state.vidH);
}

/**
 * Initiliaze debugger function to trigger navigation with keyboard
 * keys
 */
function initArrowNav() {
    document.addEventListener("keydown", (e) => {
        switch (e.code) {
            case 'ArrowLeft': // left
                NavHandler.navToDirection(false);
                break;

            case 'ArrowRight':
                NavHandler.navToDirection(true);
                break;

            default:
                return; // exit this handler for other keys
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)
    });
}

/**
 * Reconnect Handlers
 */
function reConnectPageHandlers() {
    console.log("IM: ReConnecting page handlers...");
    // Connect navigation buttons
    const navButtons = state.contentContainer.querySelectorAll(".sb-nav-btn, .sb-nav-linked");
    for (const btn of navButtons) {
        const overrideId = btn.getAttribute("sb-override-to-id");
        const overrideVidId = btn.getAttribute("sb-override-vid-id");
        if (overrideId) {
            btn.addEventListener("click", function() {
                setPage(overrideId, false, true, overrideVidId);
            })
            return;
        }

        var isRight; // temp variable to save the direction

        // get the direciton of the button
        if (btn.classList.contains("sb-next")) {
            isRight = true;
        } else if (btn.classList.contains("sb-prev")) {
            isRight = false;
        }

        btn.addEventListener("click", () => { NavHandler.navToDirection(isRight) })
    }
}