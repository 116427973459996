import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { SectionType } from "../enums";
import { Helpers } from '../handlers/helpers';
import { _lang } from '../handlers/langHandler';
import { setPage } from '../script.js';
import { Button } from '../templates/button';

const sectionStartDelay = 1.6;

            
export default {
    starTimelines: [],

    // ID of the section
    sectionId: 9,
    // slug of the section (make sure its unique)
    sectionSlug: "s9",
    // Page title of the loaded section
    sectionTitle: "Objektiv & Unabhängig.",
    // Type of the page
    type: SectionType.SLIDE,
    introVideoID: "9_10_universum_auge",
    loopVideoID: null,
    hide: '',
    loader: '',

    content: /*html*/ `<div id="s9-main-container" class="sb-above-pp sb-inner-container">
    <h2 id="s9-title"><span class="sb-entrance-container">${_lang("S9_Title")}</span></h2>
    <div id="s9-content">
    <p>${_lang("S9_Content_1")}</p>
    </div>
</div>
<div id="s9-nav-buttons">
    <a id="s9-reset-btn" class="sb-nav-linked sb-next sb-above-pp">Home</a>
    <div id="s9-pages-btns" class="sb-above-pp">
        <a id="s9-methoden-btn" class="sb-nav-linked">${_lang("methods")}</a><br>
        <a id="s9-kontakt-btn" class="sb-nav-linked">${_lang("SB_Button_Contact")}</a>
    </div>
</div>

<div id="s9-stern4" class="s9-ster  sb-section-el"><img src="/page-assets/section-09/Stern-01.png"></div>
<div id="s9-stern5" class="s9-ster  sb-section-el"><img src="/page-assets/section-09/Stern-03.png"></div>

<!--<div id="s9-stern1" class="s9-ster  sb-section-el"><img src="/page-assets/section-09/Stern-01.png"></div>-->
<div id="s9-stern2" class="s9-ster sb-section-el"><img
        src="/page-assets/section-09/Stern-02.png"></div>
<div id="s9-stern3" class="s9-ster  sb-section-el"><img
        src="/page-assets/section-09/Stern-03.png"></div>`,

    onLoad: () => {
        const reset = document.querySelector("#s9-reset-btn");
        const methoden = document.querySelector("#s9-methoden-btn")
        const kontakt = document.querySelector("#s9-kontakt-btn")

        reset.addEventListener("click", () => { setPage("home", true) })
        methoden.addEventListener("click", () => { setPage("methoden", true) })
        kontakt.addEventListener("click", () => { setPage("kontakt", true) })
    },

    dispose: function() {
        for (const tl of this.starTimelines) {
            tl.kill();
        }
    },

    show: function(fastSwitch) {
        return new Promise(function(resolve, reject) {
            const splitText = new SplitText("#s9-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from(".s9-ster", { opacity: 0, duration: .6 }, "<");
            tl.from("#s9-content", { opacity: 0 })
                // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)

            this.starTimelines.push(animStar("#s9-stern4 img"));
            this.starTimelines.push(animStar("#s9-stern5 img"));            
            this.starTimelines.push(animStar("#s9-stern2 img"));            
            this.starTimelines.push(animStar("#s9-stern3 img"));            
            
            function animStar(selector) {
                // Make timeline for star
                const starEl = document.querySelector(selector).parentElement;
                const starTl = gsap.timeline({ 
                  paused: false, 
                  delay: (fastSwitch) ? 0 : sectionStartDelay + gsap.utils.random(1, 3),
                  yoyo: true,
                  repeatDelay: gsap.utils.random(0.3, 3)
                });

                // scale in and out
                starTl.from(selector, { scale: 0, opacity: 0, duration: gsap.utils.random(0.3, 1.5) })
                starTl.to(selector, {
                    scale: 0,
                    opacity: 0,
                    duration: gsap.utils.random(0.2, 0.8),
                    delay: gsap.utils.random(1, 3),

                    // Repeat again after random delay
                    onComplete: function() {
                        starTl.restart(true);
                        /*starEl.style.top = Math.random() * 100 + "%";
                        starEl.style.left = Math.random() * 100 + "%";*/
                    },
                });
                return starTl;
            }
        }.bind(this));
    }
}