import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import Swiper from 'swiper';
import { Button } from '../templates/button';
import { Helpers } from '../handlers/helpers';
import { SectionType } from "../enums";
import involveLogo from '../../static/page-assets/section-07/involve.me.svg';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1.9;

export default {
    // ID of the section
    sectionId: 7,
    // slug of the section (make sure its unique)
    sectionSlug: "s7",
    // Page title of the loaded section
    sectionTitle: "Entwicklungen & Startups",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "7_8_haende_schrauben",
    loopVideoID: null,
    loader: '',

    content: /*html*/ `<div id="s7-main-container" class="sb-above-pp sb-inner-container">
    <h2 id="s7-title"><span class="sb-entrance-container">${_lang("S7_Title")}</span></h2>
    <p id="s7-content">
       ${_lang("S7_Content_1")}
    </p>
    <div id="s7-involve-logo"><a href="https://involveme.screenagers.com/" target="_blank">${involveLogo}</a></div>
    <a id="s7-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_08.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>

</div>
</div>

<span id="s7-schraube1" class="sb-section-el s7-schraube">
    <div class="sb-float">
        <img class="sb-mouse-tracking" sb-mouse-x="-15" sb-mouse-y="-15" src="/page-assets/section-07/Schraube-01.png">
    </div>
</span>
<span id="s7-schraube2" class="sb-section-el s7-schraube">
    <div class="sb-float">
        <img class="sb-mouse-tracking" sb-mouse-x="40" sb-mouse-y="40" src="/page-assets/section-07/Schraube-02.png">
    </div>
</span>
<span id="s7-schraube3" class=" sb-section-el s7-schraube">
    <div class="sb-float">
        <img class="sb-mouse-tracking" sb-mouse-x="30" sb-mouse-y="30" sb-mouse-speed="0.01"
            src="/page-assets/section-07/Schraube-03.png">
    </div>
</span>`,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            const splitText = new SplitText("#s7-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from(".sb-section-el", { scale: 0, opacity: 0, duration: .6, onComplete: resolve }, "<")
            tl.from("#s7-main-container", { opacity: 0, duration: .6 }, "<");
            tl.from("#s7-content", { opacity: 0 })
            tl.from("#s7-involve-logo", { opacity: 0 }, "<");

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }
        });
    },

    hide: () => {
        return new Promise((resolve, reject) => {
            const tl = gsap.timeline({ paused: false });
            tl.to("#s7-main-container", { opacity: 0, duration: .3 });
            tl.to(".s7-schraube", { y: "100vh", stagger: .1, duration: .6, onComplete: resolve }, "<");
        });
    },
}