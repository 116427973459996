import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { Linear } from 'gsap';
import { Button } from '../templates/button';
import { SectionType } from "../enums";
import tomorrowAcademy from '../../static/page-assets/section-08/TomorrowAcademy.svg';
import { Helpers } from '../handlers/helpers';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = .6;

export default {
    // ID of the section
    sectionId: 8,
    // slug of the section (make sure its unique)
    sectionSlug: "s8",
    // Page title of the loaded section
    sectionTitle: "Vernetzung & Expertise.",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "8_9_schrauben_universum",
    loopVideoID: null,
    loader: '',

    content: /*html*/ `<div id="s8-main-container" class="sb-above-pp sb-inner-container">
    <h2 id="s8-title"><span class="sb-entrance-container">${_lang("S8_Title")}</span></h2>
    <p id="s8-content">
        ${_lang("S8_Content_1")}
    </p>
    <div id="s8-content-logo"><a href="https://tomorrowacademy.org/" target="_blank">${tomorrowAcademy}</a></div>
    <a id="s8-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_09.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
</div>
<span id="s8-planet1" class="sb-section-el s8-planet s8-elipse-container">
    <div class="s8-elipse">
        <img  sb-mouse-x="-15" sb-mouse-y="-15" sb-mouse-speed="0.02"
            src="/page-assets/section-08/Planet-Links.png">
    </div>
</span>
<span id="s8-planet2" class="sb-section-el s8-planet s8-elipse-container">
    <div class="s8-elipse">
        <img  sb-mouse-speed="0.01" src="/page-assets/section-08/Planet-Rechts.png">
    </div>
</span>`,


    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            const splitText = new SplitText("#s8-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(" #s8-planet1", { x: 300, y: -300, opacity: 0, duration: .6 }, "<");
            tl.from("#s8-planet2", { x: 300, y: -300, opacity: 0, duration: .6, onComplete: resolve }, "<");
            tl.from("#s8-main-container", { opacity: 0, duration: .6 }, "<0.3");
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from("#s8-content", { opacity: 0 });
            tl.from("#s8-content-logo", { opacity: 0 }, "<");

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");
            const elipse1 = document.querySelector("#s8-planet1 .s8-elipse");
            const elipse2 = document.querySelector("#s8-planet2 .s8-elipse");

            const elipse2TotalTime = Math.PI * 0.25;

            var time = {
                _percent: 0,
                get percent() { return this._percent; },
                set percent(value) {
                    this._percent = value;
                }
            };
            gsap.to(time, { percent: 100, ease: Linear.easeNone, duration: 30, onUpdate: setElipsePosition.bind(this), repeat: Infinity });

            function setElipsePosition() {
                const percentage = time.percent;
                const totalTime = Math.PI * 2;
                const currentTime = percentage / 100.0 * totalTime;

                const rX = 40;
                const rZ = 60;

                const x = Math.sin(currentTime) * rX;
                const z = Math.cos(currentTime) * rZ;

                elipse1.style.transform = `translateX(${x}px) translateZ(${z}px)`

                const rX2 = 60;
                const rZ2 = 50;

                const x2 = Math.sin(-currentTime) * rX2;
                const z2 = Math.cos(-currentTime) * rZ2;
                elipse2.style.transform = `translateX(${x2}px) translateZ(${z2}px)`
            }

            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
        return new Promise((resolve, reject) => {
            const tl = gsap.timeline({ paused: false });
            tl.to("#s8-main-container", { opacity: 0, duration: .3 });
            tl.to(" #s8-planet1", { top: "calc(50% - 60px)", left: "50%", duration: .6, scale: 0.8 }, "<");
            tl.to(" #s8-planet2", { top: "calc(50% - 90px)", left: "50%", duration: .6, scale: 0.8 }, "<");
            tl.to(".s8-planet", { scale: 0, duration: .3 }, "<.3");
            tl.eventCallback("onComplete", resolve);
        });
    }
}