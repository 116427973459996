import { SectionType } from "../enums";
import { _lang } from "../handlers/langHandler";

export default {
    // ID of the section
    sectionId: "impressum",
    // slug of the section (make sure its unique)
    sectionSlug: "impressum",
    // Page title of the loaded section
    sectionTitle: "Impressum",
    // Type of the page
    type: SectionType.CUSTOM,


    content: /*html*/ `
    <div id="simpressum-main-container" class="sb-above-pp sb-inner-container">
        <h2 id="simpressum-title">${_lang("imprint")}</h2>
        <p id="simpressum-content">
        screenagers<br>
        angewandte digitale und analoge Kommunikation GmbH<br>
        Mittelgasse 4/13<br>
        1060 Wien<br>
        <a href="mailto:office@screenagers.at">office@screenagers.at</a><br>
        Tel. +4319971644
        <br>
        UID ATU64564714<br>
        FN 320355k
        </p>
    </div>

    <!--Override menu color and page colour-->
    <style>
    #sb-nav-icon *{
        stroke: black !important;
    }
    </style>
    `,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            resolve();
        })
    }
}