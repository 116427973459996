import { Languages } from "../../enums"

export default {

    // Section 0
    "S0_Welcome": {
        [Languages.DE]: "Hallo, wir sind screenagers & the incredible machine.",
        [Languages.EN]: "Hi, we’re screenagers & the incredible machine –"
    },
    "S0_Welcome_2": {
        [Languages.DE]: "Wir sind ein Team aus Strategen, Designern, Marketing-Experten und Programmierern. Wir entwickeln Business-Konzepte für die digitale Welt.",
        [Languages.EN]: "We are a team of strategists, designers, marketing experts and programmers. We develop business concepts for the digital world."
    },

    // Section 1
    "S1_Content_1": {
        [Languages.DE]: "Wir verbinden die besten Innovations- und Designmethoden mit 20 Jahren Erfahrung in digitaler Pionierarbeit.",
        [Languages.EN]: "We combine the best innovation and design methods with 20 years of experience in digital pioneering work."
    },
    "S1_Content_2": {
        [Languages.DE]: "Wir helfen dir, innovative Nischen zu identifizieren, deinem Produkt die richtige Gestalt zu geben und die beste technische Lösung umzusetzen.",
        [Languages.EN]: "We’ll help you identify innovative niches, shape your product perfectly and implement the best technical solution."
    },
    "S1_Content_3": {
        [Languages.DE]: "Wir sind deine Guides durch den Dschungel der digitalen Möglichkeiten.",
        [Languages.EN]: "We are your guides through the jungle of digital possibilities."
    },

    // Section 2 
    "S2_Title": {
        [Languages.DE]: "Beratung mit echten Ergebnissen.",
        [Languages.EN]: "Results you can count on."
    },
    "S2_Content_1": {
        [Languages.DE]: "Wir arbeiten mit dir in kompakten Sprints an deinem Projekt. So lange, bis es auf eigenen Beinen stehen kann. ",
        [Languages.EN]: "We work with you to develop your project in compact sprints – until it can stand on its own two feet. "
    },
    "S2_Content_2": {
        [Languages.DE]: "Wir identifizieren die richtige Strategie, planen die technische Lösung, entwickeln ein einzigartiges Design, das im Markt funktioniert. ",
        [Languages.EN]: "We’ll identify the right strategy, plan the technical solution and develop a unique design that works in the market. "
    },

    // Section 3
    "S3_Title": {
        [Languages.DE]: "Aus der Praxis<br>für die Praxis.",
        [Languages.EN]: "Applied experience for<br>results that work."
    },
    "S3_Content_1": {
        [Languages.DE]: "Aus der Erfahrung von über 1000 Projekten wissen wir genau, was funktioniert. Wir kombinieren kreative Exzellenz mit technischer Expertise und Business-Know-How.",
        [Languages.EN]: "The experience we’ve garnered from over 1000 projects means we know exactly what works. We combine creative excellence with technical expertise and business know-how."
    },
    "S3_Content_2": {
        [Languages.DE]: "Wir kennen den Markt genau und helfen dir, die für dich richtige Lösung zu finden und umzusetzen. Egal ob mit oder ohne uns.",
        [Languages.EN]: "We’re very familiar with the market and will help you find and implement the right solution for you. With or without us."
    },

    // Section 4
    "S4_Title": {
        [Languages.DE]: "Wir sind Incredible!",
        [Languages.EN]: "We are Incredible!"
    },
    "S4_Content_1": {
        [Languages.DE]: "Wir entwickeln Strategien. Wir positionieren Marken. Wir erfinden Produkte.",
        [Languages.EN]: "We develop strategies. We position brands. We invent products."
    },

    // Section 5
    "S5_Title": {
        [Languages.DE]: "Exzellenz seit 1999.",
        [Languages.EN]: "Excellence since 1999."
    },
    "S5_Content_1": {
        [Languages.DE]: "Als eines der ersten digitalen Design-Studios des Landes haben wir mehr als 1000 Projekte erfolgreich umgesetzt. Wir haben Marken eine Gestalt gegeben, Kampagnen realisiert und Software entwickelt.",
        [Languages.EN]: "As one of the first digital design studios in the country, we’ve successfully implemented more than 1000 projects. We’ve shaped brands, implemented campaigns and developed software."
    },

    // Section 6
    "S6_Title": {
        [Languages.DE]: "Awards & Auszeichnungen",
        [Languages.EN]: "Awards and accolades."
    },
    "S6_Content_1": {
        [Languages.DE]: "Wir sind für unsere kreativen Lösungen national und international mehr als 100x ausgezeichnet worden und waren Teil der wichtigsten Design-Jurys der Welt.",
        [Languages.EN]: "We’ve received more than 100 national and international awards for our creative solutions and have been part of some of the world’s most important design juries."
    },

    // Section 7
    "S7_Title": {
        [Languages.DE]: "Entwicklungen<br>& Startups.",
        [Languages.EN]: "Developments<br>& Start-ups."
    },
    "S7_Content_1": {
        [Languages.DE]: "Wir reden nicht nur über Technologie und Business. Wir haben innovative Software-Startups gegründet und aufgebaut, bis sie am Markt bestehen konnten.",
        [Languages.EN]: "We're not just talking about technology and business. We founded and developed innovative software start-ups until they were viable on the market."
    },

    // Section 8
    "S8_Title": {
        [Languages.DE]: "Vernetzung & Expertise",
        [Languages.EN]: "Networking & Expertise."
    },
    "S8_Content_1": {
        [Languages.DE]: "Wir sind Mitbegründer der Tomorrow Academy, einem führenden Seminaranbieter für die Kreativ- und Marketingbranche - und sind dadurch mit internationalen Experten für jede Fragestellung vernetzt.",
        [Languages.EN]: "We’re co-founders of the Tomorrow Academy, a leading seminar provider for the creative and marketing industry – which means we’re networked with international experts to find the right answer to any question."
    },
    // Section 9
    "S9_Title": {
        [Languages.DE]: "Objektiv & unabhängig",
        [Languages.EN]: "Objective & Independent."
    },
    "S9_Content_1": {
        [Languages.DE]: "Wir bringen praxisnahes, digitales Know-How und den notwendigen Blick von außen in dein Unternehmen und helfen dir, die für dich optimale Lösung zu finden.",
        [Languages.EN]: "We provide your company with practical, digital know-how and the critical viewpoints it needs, and help you find the best solution for you."
    },
    "Contact_Content_1": {
        [Languages.DE]: "Neugierig? Vereinbare gleich Deine kostenlose Discovery Session.<br><br>Gemeinsam finden wir heraus ob Digital Business Design die richtige Anwort auf Deine Fragestellung ist und was der richtige Weg sein könnte.",
        [Languages.EN]: "Curious? Arrange your free discovery session now.<br><br>Together we will find out whether digital business design is the right answer to your question and what the right path for you could look like."
    },
}