import { SectionType } from "../enums";

export default {
    // ID of the section
    sectionId: "datenschutz",
    // slug of the section (make sure its unique)
    sectionSlug: "datenschutz",
    // Page title of the loaded section
    sectionTitle: "Datenschutz",
    // Type of the page
    type: SectionType.CUSTOM,

    content: /*html*/ `
    
    <div id="sdatenschutz-main-wrap">
    <div id="sdatenschutz-main-container" class="sb-above-pp sb-inner-container">
        <h2 id="sdatenschutz-title">Datenschutz</h2>
        <p id="sdatenschutz-content">
Datenschutzerklärung
<p>
Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.
Kontakt mit uns
</p><p>
Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
Cookies
</p><p>
Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
Web-Analyse
</p><p>
Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Dazu werden Cookies verwendet, die eine Analyse der Benutzung der Website durch Ihre Benutzer ermöglicht. Die dadurch erzeugten Informationen werden auf den Server des Anbieters übertragen und dort gespeichert. Sie können dies verhindern, indem Sie Ihren Browser so einrichten, dass keine Cookies gespeichert werden. Wir haben mit dem Anbieter einen entsprechenden Vertrag zur Auftragsdatenverarbeitung abgeschlossen. Ihre IP-Adresse wird erfasst, aber umgehend pseudonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung möglich.
</p><p>
Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen (berechtigtes Interesse) der DSGVO. Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert. Die Nutzerdaten werden für die Dauer von 24 Monaten aufbewahrt.
Ihre Rechte
</p><p>
Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.
Kontakt
</p><p>
screenagers GmbH<br>
Mittelgasse 4/13<br>
1060 Wien<br>
privacy@screenagers.com
        </p>
    </div>
    </div>

    <!--Override menu color and page colour-->
    <style>
    #sb-nav-icon *{
        stroke: black !important;
    }
    </style>
    `,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            resolve();
        })
    }
}