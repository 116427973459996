let isReady = true;
let ismobile = false; 0

var pointerPercentages = {};

const defaultXOffset = 30;
const defaultYOffset = 30;

const defaultSpeedScalar = 0.05; // speed in px per frame update

export class MouseHandler {

    static updatePointer(e) {
        if (e.type === "deviceorientation") {
            pointerPercentages = {
                x: (e.gamma + 90) / 180,
                y: (e.beta + 180) / 360
            }
        } else if (e.type === "mousemove") {
            pointerPercentages = {
                x: e.clientX / window.innerWidth,
                y: e.clientY / window.innerHeight
            }
        }
        this.updateElementsDestPos();
    }

    static updateElementsDestPos() {
        const elements = document.querySelectorAll("*[show-ended='true'] .sb-mouse-tracking");
        elements.forEach(el => {
            // Get specified offsets
            let maxXOffset = Number(el.getAttribute("sb-mouse-x"));
            let maxYOffset = Number(el.getAttribute("sb-mouse-y"));

            /**
             * If there are no max positions set by default set defaults
             */
            if (!maxXOffset) {
                maxXOffset = defaultXOffset;
                el.setAttribute("sb-mouse-x", maxXOffset);
            }
            if (!maxYOffset) {
                maxYOffset = defaultYOffset;
                el.setAttribute("sb-mouse-y", maxYOffset);
            }

            if (ismobile) {
                maxXOffset = -maxXOffset * 3.5;
                maxYOffset = -maxYOffset * 3.5;
            }

            // Calculate the destination to move towards
            const destX = -maxXOffset + pointerPercentages.x * (2 * maxXOffset);
            const destY = -maxYOffset + pointerPercentages.y * (2 * maxYOffset);

            // update the destination attributes
            el.setAttribute("sb-mouse-x-dest", destX);
            el.setAttribute("sb-mouse-y-dest", destY);
        });
    }

    /**
     * Initialize the mouse tracking funcitonality
     */
    static setupTracking() {
        if (window.innerWidth <= 786) {
            ismobile = true;
            //window.addEventListener("deviceorientation", this.updatePointer.bind(this));
        } else {
            animateTracking();
            window.addEventListener("mousemove", this.updatePointer.bind(this));
        }
        function animateTracking() {
            const elements = document.querySelectorAll("*[show-ended='true'] .sb-mouse-tracking");

            for (const el of elements) {
                // Determine the speed to move at
                let speed;
                if (el.hasAttribute("sb-mouse-speed")) {
                    speed = el.getAttribute("sb-mouse-speed");
                } else {
                    speed = defaultSpeedScalar;
                }


                // Get the current destination
                const destX = Number(el.getAttribute("sb-mouse-x-dest"));
                const destY = Number(el.getAttribute("sb-mouse-y-dest"));

                // Get the current location of the element
                let currentX = Number(el.getAttribute("sb-mouse-current-x"));
                let currentY = Number(el.getAttribute("sb-mouse-current-y"));

                // If current positions are unknown calculate from center
                if (isNaN(currentX)) { currentX = 0.0 };
                if (isNaN(currentY)) { currentY = 0.0 };

                // Calculate the difference
                const dX = (destX - currentX) * speed;
                const dY = (destY - currentY) * speed;

                // Calculate the new location
                const newX = currentX + dX;
                const newY = currentY + dY;

                // Update new current positions
                el.setAttribute("sb-mouse-current-x", newX);
                el.setAttribute("sb-mouse-current-y", newY);

                el.style.transform = `translateX(${newX}px) translateY(${newY}px) `
            }
            requestAnimationFrame(animateTracking.bind(this));
        }
    }

}